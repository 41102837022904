import React, { useState, useEffect } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebaseConfig"; // Import the Firebase storage instance
import CancelIcon from '@mui/icons-material/Cancel';

function FileUpload({ onUpload, preview = [], uniqueKey = 0 }) {
  const [files, setFiles] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState(preview);

  // Update downloadURLs and files when preview change
  useEffect(() => {
    setDownloadURLs(preview);
  }, [preview]);

  // Handle file selection
  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length) {
      setFiles([...files, ...selectedFiles]); // Append new files to existing list
      await handleUploads(selectedFiles);
    }
  };

  // Handle drag-and-drop
  const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length) {
      setFiles([...files, ...droppedFiles]); // Append dropped files to existing list
      await handleUploads(droppedFiles);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handle multiple file uploads to Firebase
  const handleUploads = async (fileList) => {
    const newURLs = [];
    for (const file of fileList) {
      try {
        const url = await handleSingleUpload(file);
        newURLs.push(url);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
    setDownloadURLs([...downloadURLs, ...newURLs]);
    setFiles([]); // Clear the file list after uploading
    // Pass all uploaded URLs to the parent component
    if (onUpload) {
      onUpload([...downloadURLs, ...newURLs]);
    }
  };

  // Handle single file upload
  const handleSingleUpload = async (file) => {
    function generateRandomKey() {
      const dateTime = new Date().getTime(); // Current time in milliseconds
      const randomChars = Math.random().toString(36).substring(2, 7); // Random 5-letter string
      return `${dateTime}-${randomChars}`;
    }

    const randomKey = generateRandomKey();
    const fileKey = `uploads/${randomKey}_${file.name}`;
    const storageRef = ref(storage, fileKey);

    // Upload the file and return its URL
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  // Clear selected files and previews
  const handleClear = () => {
    setFiles([]);
    setDownloadURLs([]);
    if (onUpload) {
      onUpload([]);
    }
  };

  // Combine new previews and preview for display
  const previewImages = () => {
    const newFilePreviews = files.map((file) => URL.createObjectURL(file));
    return [...downloadURLs, ...newFilePreviews];
  };

  return (
    <div
      onClick={() => document.getElementById(`fileInput${uniqueKey}`).click()} // Open file picker when clicking anywhere on the div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="relative"
      style={{
        width: "100%",
        minHeight: "150px",
        border: "2px dashed #999",
        borderRadius: "10px",
        color: "#555",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <div
        className="absolute flex flex-col items-center justify-center"
        style={{
          color: "#fff",
          backgroundColor: "#48484863",
          width: "100%",
          height: "100%",
        }}
      >
        <p>Drag & Drop to Upload Product Images</p>
        <p>OR</p>
        <p style={{ margin: 0 }}>Browse Files</p>
      </div>

      <input
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id={"fileInput" + uniqueKey}
        multiple // Allow multiple file selection
      />

      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "20px" }}>
        {previewImages().map((preview, index) => (
          <img
            key={index}
            src={preview}
            alt={`Preview ${index + 1}`}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              margin: "10px",
              borderRadius: "8px",
            }}
          />
        ))}
      </div>

      {files.length > 0 && (
        <CancelIcon
          className="absolute"
          style={{ top: "-16px", right: "-16px" }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the file picker
            handleClear();
          }}
        ></CancelIcon>
      )}
    </div>
  );
}

export default FileUpload;
