import { useState } from "react";
import { Typography } from "@mui/material";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Fragment } from "react";
import "./CreateProductForm.css";
import { useDispatch } from "react-redux";
import CancelIcon from '@mui/icons-material/Cancel';
import { createProduct } from "../../../Redux/Customers/Product/Action";
import { navigation } from "../../../config/navigationMenu";
import { useNavigate } from 'react-router-dom';
import FileUpload from "../../../services/fileUploader";


const initialSizes = [
  { name: "S", quantity: 0 },
  { name: "M", quantity: 0 },
  { name: "L", quantity: 0 },
  { name: "XL", quantity: 0 },
  { name: "XXL", quantity: 0 },
  { name: "XXXL", quantity: 0 },
];

const CreateProductForm = () => {
  const navigate = useNavigate();

  const [isDesabledSubmitBtn, setIsDesabledSubmitBtn] = useState(false);
  const [productData, setProductData] = useState({
    imageUrl: "",
    colorWiseImages: [{color:"",images:[],totalQuantity:"",size:initialSizes}],
    brand: "",
    title: "",
    color: "",
    discountedPrice: "",
    price: "",
    discountPersent: "",
    // size: initialSizes,
    quantity: "",
    topLavelCategory: "",
    secondLavelCategory: "",
    thirdLavelCategory: "",
    // description: "",
    descriptions: [{text:"", type:"Heading"},{text:"", type:"Description"}],
  });
const dispatch=useDispatch();
const jwt=localStorage.getItem("jwt")
const handleChange = (e) => {
  const { name, value } = e.target;
  setProductData((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

const handleDesChange = (e, index, des) => {
  const { value } = e.target;
  des.text = value;
  setProductData((prevState) => {
    const updatedDescriptions = [...(prevState.descriptions || [])];
    updatedDescriptions[index] = des; // Update the specific index
    return {
      ...prevState,
      descriptions: updatedDescriptions,
    };
  });
}

const handleColorWiseImageChange = (e) => {
  const { name, value } = e.target;

  // Extract the index from the name (e.g., "color0" -> 0)
  const index = parseInt(name.replace("color", ""), 10);

  setProductData((prevState) => {
    const updatedColorWiseImages = [...prevState.colorWiseImages];
    // Update the color for the corresponding index
    updatedColorWiseImages[index].color = value;

    return {
      ...prevState,
      colorWiseImages: updatedColorWiseImages,
    };
  });
};
const handleColorWiseTotalQuantityChange = (e, colorIndex) => {
  const { value } = e.target;

  setProductData((prevState) => {
    const updatedColorWiseImages = [...prevState.colorWiseImages];
    // Update the totalQuantity for the corresponding colorIndex
    updatedColorWiseImages[colorIndex].totalQuantity = value;

    return {
      ...prevState,
      colorWiseImages: updatedColorWiseImages,
    };
  });
};



  const handleFileUpload = (url, index) => {
    setProductData((prevState) => {
      const updatedColorWiseImages = [...prevState.colorWiseImages];
      updatedColorWiseImages[index].images = url; // Replace existing images with the new one
      return {
        ...prevState,
        colorWiseImages: updatedColorWiseImages,
      };
    });
  
    // Optional: You can use a timeout if you need to log the data or process it further
    setTimeout(() => {
      console.log(productData);
    });
  };
  
  

  // const handleSizeChange = (e, index) => {
  //   let { name, value } = e.target;
  //   name==="size_quantity"?name="quantity":name=e.target.name;

  //   const sizes = [...productData.size];
  //   sizes[index][name] = value;
  //   setProductData((prevState) => ({
  //     ...prevState,
  //     size: sizes,
  //   }));
  // };

  // const handleSizeChange = (e, colorIndex, sizeIndex) => {
  //   let { name, value } = e.target;
  //   name = name === "size_quantity" ? "quantity" : name;
  
  //   // Create a deep copy of colorWiseImages
  //   const updatedColorWiseImages = [...productData.colorWiseImages];
  
  //   // Update the specific size value within the specified color index
  //   updatedColorWiseImages[colorIndex].size[sizeIndex].quantity = value;
  
  //   // Update the state with the modified colorWiseImages array
  //   setProductData((prevState) => ({
  //     ...prevState,
  //     colorWiseImages: updatedColorWiseImages,
  //   }));
  // };
  
  const handleSizeChange = (e, colorIndex, sizeIndex) => {
    const { value } = e.target;
  
    setProductData((prevState) => {
      const updatedColorWiseImages = prevState.colorWiseImages.map((color, cIndex) => {
        if (cIndex === colorIndex) {
          // Update the size quantity for the matched colorIndex
          const updatedSizes = color.size.map((size, sIndex) => {
            if (sIndex === sizeIndex) {
              return {
                ...size,
                quantity: value, // Update the quantity for the matched sizeIndex
              };
            }
            return size;
          });
  
          return {
            ...color,
            size: updatedSizes, // Assign updated sizes back to the color object
          };
        }
        return color;
      });
  
      return {
        ...prevState,
        colorWiseImages: updatedColorWiseImages,
      };
    });
    setTimeout(() => {
      console.log(productData);
    }, 1000);
  };
  

  // const handleAddSize = () => {
  //   const sizes = [...productData.size];
  //   sizes.push({ name: "", quantity: "" });
  //   setProductData((prevState) => ({
  //     ...prevState,
  //     size: sizes,
  //   }));
  // };


  const handleSubmit = (e) => {
    setIsDesabledSubmitBtn(true);
    let productBody = JSON.parse(JSON.stringify(productData));
    // productBody.description = productBody?.descriptions?.join("/*/");
    // delete productBody?.descriptions;
    productBody.imageUrl = productBody?.colorWiseImages?.[0]?.images?.[0] ?? "";
    e.preventDefault();
    dispatch(createProduct({data:productBody,jwt}))
    .then(()=>{
      setIsDesabledSubmitBtn(false);
      // navigate('/admin/products');
    })
    console.log(productBody);
  };

  return (
    <Fragment>
      <Typography
        variant="h3"
        sx={{ textAlign: "center" }}
        className="py-10 text-center "
      >
        Add New Product
      </Typography>
      <form
        onSubmit={handleSubmit}
        className="createProductContainer min-h-screen"
      >
        <Grid container spacing={2}>
          {productData.colorWiseImages.map((item, colorIndex) => (
            <Grid item xs={12} key={`colorWiseImage-${colorIndex}`}>
              <div style={{border: "1px solid #3b3b4c", padding: "12px", borderRadius: "7px"}} className="relative">
                <div className="flex">
                  <TextField
                    fullWidth
                    style={{ marginBottom: "8px" }}
                    label={"Color " + (colorIndex + 1)}
                    name={"color"+colorIndex}
                    value={item.color}
                    onChange={handleColorWiseImageChange}
                    required
                  />
                  <span style={{width: "35px"}}></span>
                  <TextField
                    fullWidth
                    label={"Total Quantity" + (colorIndex + 1)}
                    name={"quantity"+colorIndex}
                    value={item.totalQuantity}
                    onChange={(event) => handleColorWiseTotalQuantityChange(event, colorIndex)}
                    required
                    type="number"
                  />
                </div>
                <FileUpload preview={item.images} uniqueKey={colorIndex} onUpload={(url)=>handleFileUpload(url, colorIndex)}/>
                {item?.size?.map((size, sizeIndex) => (
                <Grid container item spacing={3} >
                  <Grid item xs={6} sm={6}>
                    <TextField
                      label="Size Name"
                      key={"size_name"+sizeIndex+colorIndex}
                      name={"name"+sizeIndex+colorIndex}
                      style={{ marginTop: "25px" }}
                      disabled={true}
                      value={size.name}
                      // onChange={(event) => handleSizeChange(event, colorIndex, sizeIndex)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      key={"size_quantity"+sizeIndex+colorIndex}
                      label="Quantity"
                      name={"quantity"+sizeIndex+colorIndex}
                      type="number" style={{ marginTop: "25px" }}
                      // required
                      onChange={(event) => handleSizeChange(event, colorIndex, sizeIndex)}
                      fullWidth
                    />
                  </Grid> 
                </Grid>
                ))}
                <CancelIcon
                    className="absolute"
                    style={{ top: "-16px", right: "-16px", cursor: "pointer" }}
                    onClick={() => {
                      const updatedDescriptions = productData.colorWiseImages.filter(
                        (_, i) => i !== colorIndex
                      );
                      setProductData((prev) => ({
                        ...prev,
                        colorWiseImages: updatedDescriptions,
                      }));
                    }}
                  />
              </div>
            </Grid>
          ))}
          <Grid item xs={12}>
              <div style={{display:'flex', justifyContent:'end'}}>
                  <Button style={{padding: '6px'}} variant="contained"
                    sx={{ p: 1.8 }} className="py-20 ml-10" size="large"
                    onClick={() => {
                      setProductData((prev) => ({
                        ...prev, colorWiseImages: [...(prev?.colorWiseImages || []), {color:"",images:[],totalQuantity:"",size:initialSizes}],
                      }));
                    }}>
                    Add Color
                  </Button>
              </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Brand"
              name="brand"
              value={productData.brand}
              onChange={handleChange}
              required
            />
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Title"
              name="title"
              value={productData.title}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Quantity"
              name="quantity"
              value={productData.quantity}
              onChange={handleChange}
              required
              type="number"
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              value={productData.price}
              onChange={handleChange}
              required
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Discounted Price"
              name="discountedPrice"
              value={productData.discountedPrice}
              onChange={handleChange}
              required
              type="number"
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Discount Percentage"
              name="discountPersent"
              value={productData.discountPersent}
              onChange={handleChange}
              required
              type="number"
            />
          </Grid>
          {productData?.descriptions?.map((description, index) => (
              <Grid item xs={12} key={index}>
                <div className="relative">
                  <TextField
                    fullWidth
                    id={`description-${index}`}
                    label={description?.type}
                    multiline
                    name="description"
                    rows={1}
                    onChange={(e) => handleDesChange(e, index, description)}
                    value={description.text}
                  />
                  <CancelIcon
                    className="absolute"
                    style={{ top: "-16px", right: "-16px", cursor: "pointer" }}
                    onClick={() => {
                      // Remove the current index from the descriptions array
                      const updatedDescriptions = productData.descriptions.filter(
                        (_, i) => i !== index
                      );
                      // Update the state with the filtered array
                      setProductData((prev) => ({
                        ...prev,
                        descriptions: updatedDescriptions,
                      }));
                    }}
                  />
                </div>
              </Grid>
            ))}

          <Grid item xs={12}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                  <Button style={{padding: '6px'}}
                    variant="contained"
                    sx={{ p: 1.8 }}
                    className="py-20 ml-10"
                    size="large"
                    onClick={() => {
                      setProductData((prev) => ({
                        ...prev,
                        descriptions: [...(prev?.descriptions || []), {text:"", type:"Heading"}],
                      }));
                    }}>
                    Heading
                  </Button>
                  <Button style={{padding: '6px'}}
                    variant="contained"
                    sx={{ p: 1.8 }}
                    className="py-20 ml-10"
                    size="large"
                    onClick={() => {
                      setProductData((prev) => ({
                        ...prev,
                        descriptions: [...(prev?.descriptions || []), {text:"", type:"Description"}],
                      }));
                    }}>
                    Description
                  </Button>
              </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Top Level Category</InputLabel>
              <Select
                name="topLavelCategory"
                value={productData.topLavelCategory}
                onChange={handleChange}
                required
                label="Top Level Category"
              >
                {
                  navigation?.categories?.map((x)=>(
                    <MenuItem required value={x?.id}>{x?.name}</MenuItem>
                  ))
                }
                {/* <MenuItem value="men">Men</MenuItem>
                <MenuItem value="women">Women</MenuItem>
                <MenuItem value="kids">Kids</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Second Level Category</InputLabel>
              <Select
                name="secondLavelCategory"
                value={productData.secondLavelCategory}
                onChange={handleChange}
                required
                label="Second Level Category"
              >
                {
                  navigation?.categories?.filter((y=>(y?.id === productData.topLavelCategory)))[0]?.sections?.map((x)=>(
                    <MenuItem required value={x?.id}>{x?.name}</MenuItem>
                  ))
                }
                {/* <MenuItem value="clothing">Clothing</MenuItem>
                <MenuItem value="accessories">Accessories</MenuItem>
                <MenuItem value="brands">Brands</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <InputLabel>Third Level Category</InputLabel>
              <Select
                name="thirdLavelCategory"
                value={productData.thirdLavelCategory}
                onChange={handleChange}
                required
                label="Third Level Category"
              >
                {
                  // navigation?.categories[0]?.sections[0]?.items?.map((x)=>(
                  //   <MenuItem value={x?.id}>{x?.name}</MenuItem>
                  // ))
                  navigation?.categories?.filter((y=>(y?.id === productData.topLavelCategory)))[0]?.sections?.filter((z=>(z?.id === productData.secondLavelCategory)))[0]?.items?.map((x)=>(
                    <MenuItem required value={x?.id}>{x?.name}</MenuItem>
                  ))
                }
                {/* <MenuItem value="top">Tops</MenuItem>
                <MenuItem value="women_dress">Dresses</MenuItem>
                <MenuItem value="t-shirts">T-Shirts</MenuItem>
                <MenuItem value="saree">Saree</MenuItem>
                <MenuItem value="lengha_choli">Lengha Choli</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>

          {/* {productData.size.map((size, index) => (
            <Grid container item spacing={3} >
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Size Name"
                  name="name"
                  value={size.name}
                  onChange={(event) => handleSizeChange(event, index)}
                  // required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Quantity"
                  name="size_quantity"
                  type="number"
                  onChange={(event) => handleSizeChange(event, index)}
                  fullWidth
                />
              </Grid> </Grid>
            
          ))} */}
          <Grid item xs={12} >
            <Button
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20"
              size="large"
              type="submit"
              disabled={isDesabledSubmitBtn}
            >
              Add New Product
            </Button>
            {/* <Button
              variant="contained"
              sx={{ p: 1.8 }}
              className="py-20 ml-10"
              size="large"
              onClick={()=>handleAddProducts(dressPage1)}
            >
              Add Products By Loop
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default CreateProductForm;
