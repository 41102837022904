
import axios from 'axios';
const DEPLOYED='https://pear-poised-hen.cyclic.app/'
// const LOCALHOST='http://localhost:5454/.netlify/functions/app'
// const LOCALHOST='https://aaba-back-end.onrender.com/'
// const LOCALHOST='http://localhost:8888/.netlify/functions/app'

const LOCALHOST='https://aabagarments.netlify.app/.netlify/functions/app'

export const API_BASE_URL = LOCALHOST;

const api = axios.create({
  baseURL: API_BASE_URL,
});

const token = localStorage.getItem('jwt');

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

api.defaults.headers.post['Content-Type'] = 'application/json';

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt');
    const loginTime = localStorage.getItem('loginTime');

    if (token && loginTime) {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - parseInt(loginTime, 10);
      const fortyEightHoursInMs = 48 * 60 * 60 * 1000;

      if (timeElapsed > fortyEightHoursInMs) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject(new Error('Token expired'));
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
