import React from "react";
import HomeCarousel from "../customer/Components/Carousel/HomeCarousel";
import { homeCarouselData } from "../customer/Components/Carousel/HomeCaroselData";
import HomeProductSection from "../customer/Components/Home/HomeProductSection";
import { sareePage1 } from "../Data/Saree/page1";
import { dressPage1 } from "../Data/dress/page1";
import { gounsPage1 } from "../Data/Gouns/gouns";
import { kurtaPage1 } from "../Data/Kurta/kurta";
import { mensShoesPage1 } from "../Data/shoes";
import { mens_kurta } from "../Data/Men/men_kurta";
import { lengha_page1 } from "../Data/Women/LenghaCholi";
import { navigation } from "../config/navigationMenu"
import { useEffect, useState } from 'react';
import { getAllProducts, getAllProductsByCategoryWise } from '../Redux/Customers/Product/Action';
import { useDispatch, useSelector } from "react-redux";


const Homepage = () => {
  const dispatch = useDispatch();
  const { customersProduct } = useSelector((store) => store);

  const [productList, setProductList] = useState([]);

  useEffect(() => {
    // dispatch(getAllProducts());
    dispatch(getAllProductsByCategoryWise());
  }, []);
  useEffect(() => {
    let products = [];
    navigation?.categories?.forEach((x => {
      x?.sections?.forEach((y) =>{
        y?.items?.forEach((z)=>{
          products?.push({
            name: z?.name,
            id: "/" + x?.id + "/" + y?.id + "/" + z?.id,
            product: []
          })
        })
      })
    }))
console.log(products)
    // let products = [
    //   { name: 'Tops', id:"/women/clothing/top", product: []},
    //   { name: 'Dresses', id:"/women/clothing/women_dress", product: []},
    //   { name: 'Women Jeans', id: '/women/clothing/women_jeans' , product: []},
    //   { name: 'Lengha Choli', id: '/women/clothing/lengha_choli' , product: []},
    //   { name: 'Sweaters', id: '/women/clothing/sweater' , product: []},
    //   { name: 'T-Shirts', id: '/women/clothing/t-shirt' , product: []},
    //   { name: 'Jackets', id: '/women/clothing/jacket' , product: []},
    //   { name: 'Gouns', id: '/women/clothing/gouns' , product: []},
    //   { name: 'Sarees', id: '/women/clothing/saree' , product: []},
    //   { name: 'Kurtas', id: '/women/clothing/kurtas' , product: []},
    // ];
    customersProduct?.products?.content?.forEach((product)=>{
      products?.forEach((x)=>{
        let endpoint = x?.id?.split('/');
        if((endpoint[endpoint?.length -1]) === product?.category?.name && x?.product?.length <= 9){
          x.product?.push(product);
        }
      })
    })
    setProductList(products);
    console.log(customersProduct)
  }, [customersProduct]);
  return (
    <div className="">
      <HomeCarousel images={homeCarouselData} />
      <div className="space-y-10 py-20">
        {
          productList?.map((x)=>(x?.product?.length > 0 ? <HomeProductSection data={x?.product} section={x?.name} route={x?.id}/> : <></>))
        }
      {/* <HomeProductSection data={mens_kurta} section={"Men's Kurta"} />
        <HomeProductSection data={mensShoesPage1} section={"Men's Shoes"} />
        <HomeProductSection data={lengha_page1} section={"Lengha Choli"} /> */}
        {/* <HomeProductSection data={sareePage1} section={"Saree"} />
        <HomeProductSection data={dressPage1} section={"Dress"} />
        <HomeProductSection data={gounsPage1} section={"Women's Gouns"} />
        <HomeProductSection data={kurtaPage1} section={"Women's Kurtas"} /> */}
        {/* <HomeProductSection data={mensPantsPage1} section={"Men's Pants"} /> */}
      </div>

      
    </div>
  );
};

export default Homepage;
